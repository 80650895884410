import React, {memo} from 'react';

import PropTypes from 'prop-types';

import {PanelHeader, PanelWrapper, PanelContent} from './styled';

const Panel = ({
  title,
  children,
  dark,
  noPadding,
  desktop,
  mobile,
  contentCss,
  headerCss,
  css,
  ...props
}) => {
  return (
    <PanelWrapper
      desktop={desktop}
      mobile={mobile}
      css={css}
      dark={dark}
      {...props}>
      {title ? (
        <PanelHeader css={headerCss} dark={dark}>
          {title}
        </PanelHeader>
      ) : null}
      <PanelContent css={contentCss} noPadding={noPadding}>
        {children}
      </PanelContent>
    </PanelWrapper>
  );
};

Panel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  css: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  contentCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  headerCss: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
  dark: PropTypes.bool,
  noPadding: PropTypes.bool,
};

export default memo(Panel);
