import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {
  blue,
  darkGray,
  gray,
  lightGray,
  lightRed,
} from '@renofi/utils/src/colors';
import {mediaQuery} from '@renofi/utils';

import * as ALL_ICONS from './img';

const getIcon = (icon) => {
  return ALL_ICONS[icon] || '';
};

export const IconWrapper = styled(Flex)(
  {},
  ({error, icon, iconCss, iconPosition, small, xLarge}) => {
    const isIconRight = iconPosition === 'right';
    const border = isIconRight ? 'borderLeft' : 'borderRight';
    const borderCornerTop = isIconRight
      ? 'borderTopRightRadius'
      : 'borderTopLeftRadius';
    const borderCornerBottom = isIconRight
      ? 'borderBottomRightRadius'
      : 'borderBottomLeftRadius';
    const borderColor = error ? lightRed : '#dcdcdc';

    return {
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: 'auto',
      [borderCornerTop]: small ? 4 : 8,
      [borderCornerBottom]: small ? 4 : 8,
      width: icon ? (xLarge ? 55 : 45) : 'auto',
      background: icon
        ? `white url(${getIcon(icon)}) 55% 50% no-repeat`
        : 'none',
      [border]: xLarge ? '' : `solid 1px ${borderColor}`,
      ...iconCss,
    };
  },
);

export const InputWrapper = styled(Flex)(
  {
    position: 'relative',
  },
  ({disabled, error, iconPosition, xLarge, small, css}) => {
    const borderColor = error ? lightRed : xLarge ? '#DCDCDC' : lightGray;

    return mediaQuery({
      alignItems: 'stretch',
      flexDirection: iconPosition === 'right' ? 'row-reverse' : 'row',
      backgroundColor: disabled ? '#F2F2F2' : 'white',
      border: `1px solid ${borderColor}`,
      borderRadius: small ? 4 : 8,
      ...css,
    });
  },
);

export const Input = styled.input(({large, small, xLarge, disabled}) => ({
  '::placeholder': {
    color: '#DCDCDC',
  },
  ':visited, :active, :focus': {
    outline: 'none',
  },
  width: '100%',
  color: disabled ? lightGray : darkGray,
  fontSize: (() => {
    if (xLarge) return 24;
    if (large) return 20;
    return 16;
  })(),
  height: (() => {
    if (xLarge) return 60;
    if (small) return 44;
    return 50;
  })(),
  padding: (() => {
    if (xLarge) return '10px 16px';
    return '10px 13px';
  })(),
  border: 0,
  background: 'none',
  opacity: disabled ? 0.7 : 1,
}));

export const Message = styled.p(
  {
    fontStyle: 'italic',
    marginTop: 8,
    marginBottom: 0,
    lineHeight: '16px',
    a: {
      cursor: 'pointer',
      fontSize: 12,
      textDecoration: 'underline',
      ':hover': {
        textDecoration: 'none',
      },
    },
  },
  ({align, color, fontSize, position, css}) =>
    mediaQuery({
      position: position || 'absolute',
      top: '94%',
      fontSize: fontSize || 12,
      textAlign: align || 'left',
      color: color ? color : blue,
      a: {
        color: color ? color : blue,
      },
      ...css,
    }),
);

export const TopMessage = styled.div(
  {
    position: 'absolute',
    fontSize: 12,
    fontStyle: 'italic',
    lineHeight: '16px',
    top: -21,
    right: 0,
    textAlign: 'right',
    letterSpacing: -0.3,
  },
  ({color}) => ({
    color: color ? color : gray,
  }),
);
