import {once} from 'lodash';

let callbackInterval = null;
let count = 0;

export function initEverflow(callback) {
  const callbackOnce = once(callback);

  const script = document.createElement('script');
  script.src = 'https://www.wj6kxc3jh.com/scripts/sdk/everflow.js';
  script.onload = () => {
    if (!window.EF) return;
    EF.click({
      offer_id: 6,
      affiliate_id: EF.urlParameter('affid'),
      sub1: EF.urlParameter('ionictag'),
      sub2: EF.urlParameter('type'),
      transaction_id: EF.urlParameter('_ef_transaction_id'),
    }).then(() => callbackOnce());

    callbackInterval = setInterval(() => {
      if (count === 10 || window.EF?.getAdvertiserTransactionId(2)) {
        callbackOnce();
        clearInterval(callbackInterval);
      }
      count++;
    }, 200);
  };
  script.onerror = callbackOnce;
  script.onabort = callbackOnce;
  document.head.appendChild(script);
}

export function everflowContext() {
  return {
    referrer: {
      type: 'everflow',
      id: window.EF ? EF.getAdvertiserTransactionId(2) : '',
    },
  };
}
