import {isNil} from 'lodash';
import * as Sentry from '@sentry/react';

const redditEvents = [
  'POS/App-Started',
  'Dashboard/App-Started',
  'Login/App-Started',
  'Secure/App-Started',
  'POS/Lead-Created',
  'Calculation/Lead-Created',
  'Dashboard/Schedule-Calendar-Success',
  'Secure/Onboarding-Splash-Presented',
  'Secure/Document-Uploaded',
];

let eventQueue = [];
let isRdtWatcherRunning = false;

export function track(event, data = {}) {
  const env = process.env.REACT_APP_ENV;

  if (env !== 'production' || !redditEvents.includes(event)) {
    return;
  }

  if (!window.rdt) {
    eventQueue.push({event, data});
    initRdtWatcher();
    return;
  }

  executeTrack(event, data);
}

function initRdtWatcher() {
  if (isRdtWatcherRunning) return;

  const checkInterval = setInterval(() => {
    if (window.rdt) {
      processEventQueue();
      clearInterval(checkInterval);
      isRdtWatcherRunning = false;
    }
  }, 500);

  isRdtWatcherRunning = true;
}

function processEventQueue() {
  eventQueue?.forEach(({event, data}) => {
    executeTrack(event, data);
  });
  eventQueue = [];
}

function mapToRedditType(event) {
  if (event === 'POS/Lead-Created') return 'Lead';
  return null;
}

function executeTrack(event, data) {
  if (!window.rdt) {
    Sentry.captureException(
      'window.rdt is not defined. Trying to call reddit track but rdt is not initialized',
    );
  }
  const redditTypeEvent = mapToRedditType(event);
  if (!isNil(redditTypeEvent)) {
    window.rdt('track', redditTypeEvent);
    return;
  }

  window.rdt('track', 'Custom', {
    customEventName: event,
    email: data.email || '',
  });
}

export default {
  track,
};
